import { newAxiosIns } from '@/libs/axios'

const URL = '/komchat-api/api/v1/broadcast'

export function apiGetBroadcast(search, cursor, limit) {
  return newAxiosIns.get(`${URL}?cursor=${cursor}&limit=${limit}&search=${search}`)
}

export function apiSendBroadcast({ param }) {
  return newAxiosIns.post(`${URL}/send-broadcast`, param)
}

export function apiSendPreview({ param }) {
  return newAxiosIns.post(`${URL}/send-preview`, param)
}

export function apiActionBroadcast({ param }) {
  return newAxiosIns.post(`${URL}/${param.id}/${param.action}`)
}

export function apiSaveTempImage({ formData }) {
  return newAxiosIns.post('/komchat-api/api/v1/media/upload/img-temp', formData)
}
