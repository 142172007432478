// export const

import { Indonesian } from 'flatpickr/dist/l10n/id'

export function isWhatsAppHasScan(data) {
  return data.filter(item => item.is_scan === true)
}

export const fieldsBroadcastTable = [
  {
    key: 'tittle',
    label: 'Judul',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'contact_total',
    label: 'Jumlah Kontak',
    thClass: 'font-bold capitalize text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'succes',
    label: 'Berhasil',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'failed',
    label: 'Gagal',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'status',
    label: 'Status Broadcast',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'action',
    label: 'Aksi',
    thClass: 'font-bold capitalize text-black',
    class: 'align-middle capitalize text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]

export const cardBroadcast = [
  {
    icon: 'https://storage.googleapis.com/komerce/assets/LP-Komchat/profile-circle.svg',
    text: 'Limit Kontak',
    cta: 'Tambah',
  },
  {
    icon: 'https://storage.googleapis.com/komerce/assets/LP-Komchat/link-2.svg',
    text: 'Nomor Terhubung',
    cta: 'Tambah',
  },
  {
    icon: 'https://storage.googleapis.com/komerce/assets/LP-Komchat/clock.svg',
    text: 'Expired',
    cta: 'Perpanjang',
  },
]

export const optionsDeliverySpeed = [
  {
    label: '5 Detik',
    value: 5,
  },
  {
    label: '10 Detik',
    value: 10,
  },
  {
    label: '1 Menit',
    value: 60,
  },
  {
    label: '3 Menit',
    value: 180,
  },
  {
    label: '5 Menit',
    value: 300,
  },
  {
    label: '10 Menit',
    value: 600,
  },
]

export const optionMode = [
  {
    label: 'Pengiriman Langsung',
    value: 'DIRECT',
  },
  {
    label: 'Pengiriman Terjadwal',
    value: 'SCHEDULED',
  },
]

export const optionsTemplate = [
  {
    label: 'Pesan Teks',
    value: 'TEXT',
    id: 0,
  }, {
    label: 'Pesan Media',
    value: 'MEDIA',
    id: 1,
  }, {
    label: 'Pesan Teks dan Pesan Media',
    value: 'TEXT_MEDIA',
    id: 2,
  },
]

export const configDate = {
  wrap: true,
  altFormat: 'j F Y',
  altInput: true,
  minDate: 'today',
  altInputClass: 'bg-white form-control',
  locale: Indonesian,
}

export const editorOption = {
  theme: 'snow',
  placeholder: 'Masukan Teks Kamu Disini',
  modules: {
    toolbar: [
      ['bold', 'italic', 'strike'],
    ],
  },
  preserveWhitespace: true,
}

export const additionalVariable = [
  {
    var: '[NAMA LENGKAP]',
  },
  {
    var: '[ALAMAT]',
  },
  {
    var: '[LIST PRODUCT]',
  },
  {
    var: '[VARI1]',
  },
  {
    var: '[VARI2]',
  },
  {
    var: '[VARI3]',
  },
]
