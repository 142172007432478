<template>
  <div>
    <div class="">
      <h5 class="text-black">
        <strong>Pengirim</strong>
      </h5>
      <div>Pengirim Broadcast</div>
    </div>
    <div class="mt-2">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Judul Template
                </h6>
              </template>
              <b-form-input
                :value="templateName"
                placeholder="Masukan judul template"
                @input="$emit('update:templateName',$event)"
              />
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Pilih Nomor Pengirim
                </h6>
              </template>
              <v-select
                :value="senderNumber"
                :options="optionSenderNumber"
                label="label"
                placeholder="Pilih Nomor Pengirim"
                @input="$emit('update:senderNumber', $event)"
              >
                <template #option="data">
                  <b-button
                    v-if="data.id === 0"
                    size="sm"
                    variant="primary"
                  >
                    {{ data.label }}
                  </b-button>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Pilih Tipe Template
                </h6>
              </template>
              <v-select
                :value="typeTemplateMessage"
                :options="optionsTemplate"
                label="label"
                placeholder="Pilih Tipe Template"
                @input="$emit('update:typeTemplateMessage', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormCheckbox,
  BSpinner,
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormGroup,
    VSelect,
  },
  props: {
    templateName: {
      type: String,
      default: '',
    },
    senderNumber: {
      type: [String, Object],
      default: () => {},
    },
    optionsTemplate: {
      type: Array,
      default: () => [],
    },
    optionSenderNumber:
    {
      type: Array,
      default: () => [],
    },
    typeTemplateMessage: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
